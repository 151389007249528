import Button from "@/components/Common/Buttons/Button/Button";
import RoommateAd from "@/components/Components/RoommateAd/RoommateAd";
import Dropdown from "@/components/Common/Inputs/Dropdown/Dropdown";
import Pagination from "@/components/Components/Pagination/Pagination";
import InputField from "@/components/Common/Inputs/Input/Input";

import PreLoader from "@/components/Common/PreLoader";

export default {
    name: 'SearchRoommateResult',
    components: {
        Button,
        RoommateAd,
        Dropdown,
        Pagination,
        InputField,
        PreLoader
    },
    data() {
        return {
            postsPerPage: 10,
            offset: 0,
            total: null,
            pages: null,

            cities: null,
            posts: null,
            form: {
                city: null,
                priceFrom: null,
                priceTo: null,
            },
            formCoolOff: false, // To prevent multiple requests,
            isLoading: false
        }
    },
    watch: {
        form: {
            handler: async function (newVal, oldVal) {
                if (this.formCoolOff) {
                    return;
                }

                let vm = this;
                vm.formCoolOff = true;
                setTimeout(async () => {
                    await vm.search();
                    vm.formCoolOff = false;
                }, 800);
            },
            deep: true
        }
    },
    methods: {
        async handlePageChange(page) {
            window.scrollTo({top: this.resultContainerRef.offsetTop});
            this.offset = page === 1 ? 0 : (page - 1) * this.postsPerPage;
            await this.search();
        },
        async search() {
            this.isLoading = true;

            this.cities = await this.$store.dispatch('location/getCitiesMapped');

            const request = {
                offset: this.offset,
                limit: this.postsPerPage
            };

            if (this.form.city !== null) {
                request.city = this.form.city;
            }
            if (this.form.priceFrom !== null) {
                request.priceFrom = this.form.priceFrom;
            }
            if (this.form.priceTo !== null) {
                request.priceTo = this.form.priceTo;
            }

            const posts = await this.$store.dispatch('roommate/getRoommatePosts', request);
            this.posts = posts.data;
            this.total = posts.total;
            this.pages = Math.ceil(this.total / this.postsPerPage);

            this.isLoading = false;
        }
    },
    async mounted() {
        this.resultContainerRef = this.$refs.resultContainer;
        await this.search();
    }
}
